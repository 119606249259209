import React from "react";
import '../../components/Layout/layout.css';

const TableColumn = ({ children }) => {
  return (
  
    <td className="table-row">
      {children}
    </td> 
  );
};

export default TableColumn;
